































import Vue from 'vue'
import { Size } from '../models/size'
import { Suit } from '../models/card'
import Icon from '../components/Icon.vue'

export default Vue.extend({
    props: {
        card: Object,
        reversed: Boolean,
        size: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            isReversed: this.reversed,

            Size
        }
    },
    computed: {
        sizeValue: function() {
            return this.size as Size
        }
    },
    methods: {
        reverse: function() {
            this.$emit('reverse')
        }
    },
    created: function() {

    },
    components: {
        Icon,
    },
})
