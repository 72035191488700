









































































import Vue from 'vue'

import { Spread, SpreadCard } from '../models/spread'

import Icon from '../components/Icon'

let defaultSpread = new Spread('Past, Present & Future', 'this is a default spread')

defaultSpread.addCard(SpreadCard.fromObject({
    number: 1,
    name: 'Past',
    description: "Things which happened in the past.",
    position: {
        x: 1,
        y: 1,
    },
}))

defaultSpread.addCard(SpreadCard.fromObject({
    number: 2,
    name: 'Present',
    position: {
        x: 3,
        y: 1,
    },
}))

defaultSpread.addCard(SpreadCard.fromObject({
    number: 3,
    name: 'Future',
    layer: 1,
    position: {
        x: 2,
        y: 2,
    },
}))

defaultSpread.addCard(SpreadCard.fromObject({
    number: 4,
    name: 'Future 2',
    layer: 2,
    rotation: 90,
    position: {
        x: 2,
        y: 2,
    },
}))

export default Vue.extend({
    data() {
        return {
            hasDrawn: false,
            spread: defaultSpread,
        }
    },
    computed: {
        width: function() {
            return Math.ceil(Math.max(...this.spread.cards.map(card => card.position.x)))
        },
        height: function() {
            return Math.ceil(Math.max(...this.spread.cards.map(card => card.position.y)))
        },
        cssGrid: function() {
            let width = this.width;
            let height = this.height;

            let css = {
                'grid-template-columns': '1fr '.repeat(width),
                'grid-template-rows': '1fr '.repeat(height),
            }

            if (width == 1) {
                css['max-width'] = '200px'
            } else if (width == 2) {
                css['max-width'] = '450px'
            }

            return css
        },
        grid: function() {
            let grid = []

            for(let card of this.spread.cards) {
                let x = card.position.x
                let y = card.position.y

                let cell = grid.find(cell => cell.x == x && cell.y == y)
                if(cell) {
                    cell.cards.push(card)
                } else {
                    grid.push({
                        x: x,
                        y: y,
                        cards: [card]
                    })
                }
            }

            return grid
        }
    },
    methods: {
        getCellCSS: function(cell) {
            let css = {
                'grid-column': `${cell.x} / span 1`,
                'grid-row': `${cell.y} / span 1`,
            }

            return css
        },

        getCardCSS: function(cell, index, card) {
            let css = {}

            let offset = 0

            let nonRotatedCards = cell.cards.filter(card => !card.rotation)

            if(nonRotatedCards.length > 1) {
                let maxOffset = 20
                let step = (maxOffset * 2) / (nonRotatedCards.length - 1)

                let offset = (step * index) - maxOffset

                css['transform'] = `translate(${offset}px, ${-offset}px)`

            }

            if(card.layer) {
                css['z-index'] = card.layer
            }

            if(card.rotation) {
                // if card is rotated, we don't set translate, so we don't
                // have to worry about overriding the previous transform
                css['transform'] = `rotate(${card.rotation}deg)`
            }

            return css
        },

        getCardCounterRotationsCSS: function(card) {
            let css = {}
            if(card.rotation) {
                css['transform'] = `rotate(${-card.rotation}deg)`
            }

            return css
        },

        getCardURL(card) {
            let cardObj = card[0]
            let isReversed = card[1]

            return cardObj.id + (isReversed ? '-r' : '')
        },

        draw: function() {
            this.hasDrawn = true;
            this.spread.draw(this.$store.state.cards);

            let spreadCardsURL = this.spread.cards.map(spreadCard => {
                let result = spreadCard.card[0].id
                if(spreadCard.card[1]) result += '-r'
                return result
            })

            window.history.replaceState({}, document.title, `/#/spread/${ this.spread.id }/${ spreadCardsURL.join('/') }`)
        }
    },
    created: function() {
        let params = this.$route.params

        if(!params.id) {
            this.$router.replace('/spreads')
            return
        }
        
        let spread = this.$store.state.spreads.find(x => x.id == params.id)

        if(!spread) {
            this.$router.replace('/spreads')
            return
        }
        this.spread = spread
        this.spread.reset()

        // try to load drawn cards from url
        try {
            if(this.$route.params.pathMatch) {

                let parts = this.$route.params.pathMatch.split('/')
                parts = parts.filter(x => x.trim().length > 0)

                if(parts.length == this.spread.cards.length) {


                    for(let i = 0; i < parts.length; i++) {
                        let cardName = parts[i]

                        let cardParts = parts[i].split('-')
                        console.log(cardName, cardParts)

                        let first = cardParts[0].toLowerCase()
                        let card = this.$store.state.cards.find(card => card.id == first)                                
                        let reversed = cardParts.length > 1 && cardParts[1] == 'r'

                        if(!card) throw `no card found for id ${ cardName }`

                        this.spread.cards[i].set(card, reversed)
                    }

                    this.hasDrawn = true;
                } else {
                    throw `incorrect number of drawn cards in url for spread`
                }
            }
        } catch(e) {
            console.warn(e)
        }
    },
    components: {
        Icon,
    }
})
